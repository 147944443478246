var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "publicPage chi-siamo", attrs: { id: "info" } },
    [
      _c("div", { staticClass: "heroContainer" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/chi_siamo_header.jpg"),
            id: "about",
          },
        }),
        _c(
          "div",
          { staticClass: "publicBookingEngine" },
          [
            _c("SearchBar", {
              attrs: {
                loading: false,
                band: _vm.activeBand,
                focus: "",
                geo: _vm.geo,
                dates: _vm.dates,
                occupancy: _vm.occupancy,
                hasResults: false,
                offset: 0,
                lastSearch: "",
                showUserBox: false,
              },
              on: {
                "submit-search": function ($event) {
                  _vm.mixinSendMutation("setTriggerSearch", true)
                  _vm.mixinGoTo("search", {})
                },
                "filter-changed": function ($event) {
                  {
                  }
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { attrs: { id: "page" } },
        [
          _c("b-container", [
            _c(
              "section",
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _c("br"),
                      _c("p", [
                        _vm._v(
                          "\n              Salabam, sviluppato da Salabam Solutions, è il prodotto che ha rivoluzionato il mondo dei cofanetti viaggio,\n              introducendo il primo voucher per viaggiare completamente digitale con ben 16 mesi di validità e oltre\n              600.000 strutture convenzionate tra hotel, appartamenti, case e B&B."
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          "\n              A differenza dei cofanetti viaggio classici, Salabam permette di consultare in tempo reale una selezione\n              premium del catalogo Expedia, il leader mondiale dell’online travel, con cui abbiamo una partnership\n              diretta e di lunga data.\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c(
              "section",
              { attrs: { id: "per-i-viaggiatori" } },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _c("h3", [_vm._v("Come utilizzare il tuo Salabam")]),
                      _c("br"),
                      _c("p", [
                        _vm._v(
                          "\n              I voucher si differenziano per destinazione, numero di notti e fascia di prezzo iniziale."
                        ),
                        _c("br"),
                        _vm._v(
                          "\n              Sono disponibili Salabam Italia, Europa e Mondo, a partire da 75 euro per notte.\n              I Salabam sono personalizzabili: sia la destinazione che il numero di notti possono essere modificati,\n              per lasciare ai viaggiatori la massima libertà di crearsi un viaggio su misura.\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", lg: "5" } }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/chi_siamo_1.png"),
                          alt: "Chi siamo: Salabam per i viaggiatori",
                        },
                      }),
                    ]),
                    _c("b-col", { attrs: { sm: "12", lg: "7" } }, [
                      _c("p", [
                        _vm._v(
                          "\n              La prenotazione avviene in due fasi."
                        ),
                        _c("br"),
                        _vm._v(
                          "\n              Prima si sceglie la tipologia di voucher, per poi tornare sul sito\n              con le credenziali del cofanetto acquistato, verificare le disponibilità e concludere la prenotazione.\n              Questo significa che ci sono ben sedici mesi di tempo per scegliere in tutta calma la destinazione.\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _c("p", [
                        _vm._v(
                          "\n              Non è necessario contattare le strutture: basta accedere al sito con le credenziali del Salabam acquistato,\n              eseguire una ricerca e scegliere tra le tantissime strutture disponibili.\n              A prenotazione conclusa, si riceve una mail con tutti i dettagli e i codici di prenotazione da mostrare\n              in struttura al momento del check-in. Saremo noi a eseguire un doppio check per accertarci che la\n              prenotazione sia andata a buon fine.\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("TornaIndietro"),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c(
              "section",
              { attrs: { id: "per-le-aziende" } },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _c("h3", [
                        _vm._v(
                          "Sei un'azienda e vuoi costruire un progetto con Salabam Solutions?"
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12", lg: "7" } }, [
                      _c("p", [
                        _vm._v(
                          "\n              A Salabam facciamo tutto in casa: dalle piattaforme, sviluppate da un team informatico interno,\n              all’assistenza clienti multicanale e multilingue, rapida e senza robot."
                        ),
                        _c("br"),
                        _vm._v(
                          "\n              Il risultato? Soggiorni in totale sicurezza presso strutture di verificata professionalità e qualità.\n              E per qualsiasi informazione o necessità, un personale dedicato risponde dall’Italia nel giro di pochi minuti.\n            "
                        ),
                      ]),
                    ]),
                    _c("b-col", { attrs: { sm: "12", lg: "5" } }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/chi_siamo_2.png"),
                          alt: "Chi siamo: Salabam per le aziende",
                        },
                      }),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "12" } }, [
                      _c("p", [
                        _vm._v(
                          "\n              Se ti interessa scoprire l’intera gamma di servizi che possiamo offrire a te o alla tua azienda,"
                        ),
                        _c("br"),
                        _vm._v("\n              visita "),
                        _c(
                          "a",
                          { attrs: { href: "https://salabamsolutions.com" } },
                          [_vm._v("il sito Salabam Solutions!")]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _c("TornaIndietro"),
              ],
              1
            ),
            _c("br"),
            _c("br"),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }