var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paymentslink" },
    [
      _vm.payment_get.status === "NEW"
        ? _c(
            "div",
            [
              !_vm.completed && !_vm.purchasing
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c("h2", { staticClass: "mb-4 pb-4" }, [
                            _vm._v("Effettua il pagamento"),
                          ]),
                        ]
                      ),
                      _c("b-col", { attrs: { md: "4", "offset-md": "1" } }, [
                        _c("p", [
                          _c("strong", [_vm._v("Tipo di servizio:")]),
                          _vm._v(" " + _vm._s(_vm.payment_get.productName)),
                        ]),
                        _c("p", [
                          _c("strong", [_vm._v("Descrizione del servizio:")]),
                          _vm._v(" " + _vm._s(_vm.payment_get.description)),
                        ]),
                        _c("p", { staticClass: "paymentslink__price mt-4" }, [
                          _vm._v(
                            "Importo: € " + _vm._s(_vm.payment_get.live_price)
                          ),
                        ]),
                        _c("p", { staticClass: "mt-4" }, [
                          _c("strong", { staticClass: "d-block mb-2" }, [
                            _vm._v("Come effettuare il pagamento"),
                          ]),
                          _vm._v(
                            "\n          Compila il modulo che trovi in questa pagina. Una volta compilato correttamente in tutti i suoi campi\n          ti verrà mostrato un modulo aggiuntivo in cui inserire i dati di carta di credito\n        "
                          ),
                        ]),
                        _c("div", { staticClass: "paymentslink__cards" }, [
                          _c("img", {
                            staticClass: "visa",
                            attrs: {
                              src: "/assets/pagamenti/visa.svg",
                              alt: "visa",
                            },
                          }),
                          _c("img", {
                            staticClass: "mastercard",
                            attrs: {
                              src: "/assets/pagamenti/mastercard.svg",
                              alt: "mastercard",
                            },
                          }),
                          _c("img", {
                            staticClass: "american-express",
                            attrs: {
                              src: "/assets/pagamenti/american-express.svg",
                              alt: "american-express",
                            },
                          }),
                        ]),
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.copy_security) },
                        }),
                      ]),
                      _c(
                        "b-col",
                        { attrs: { md: "5", "offset-md": "1" } },
                        [
                          _c("h5", {
                            domProps: {
                              innerHTML: _vm._s("Dati di fatturazione"),
                            },
                          }),
                          _c(
                            "b-form-group",
                            { attrs: { state: _vm.validateFields() } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12", sm: "6" },
                                    },
                                    [
                                      _c("b-form-input", {
                                        ref: "firstName",
                                        attrs: {
                                          required: true,
                                          placeholder: "Nome",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.fields.firstName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.fields,
                                              "firstName",
                                              $$v
                                            )
                                          },
                                          expression: "fields.firstName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12", sm: "6" },
                                    },
                                    [
                                      _c("b-form-input", {
                                        ref: "lastName",
                                        attrs: {
                                          required: true,
                                          placeholder: "Cognome",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.fields.lastName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.fields,
                                              "lastName",
                                              $$v
                                            )
                                          },
                                          expression: "fields.lastName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12", sm: "6" },
                                    },
                                    [
                                      _c("b-form-input", {
                                        ref: "fiscalCode",
                                        attrs: {
                                          required: true,
                                          placeholder: "Codice Fiscale",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.fields.fiscalCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.fields,
                                              "fiscalCode",
                                              $$v
                                            )
                                          },
                                          expression: "fields.fiscalCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12", sm: "6" },
                                    },
                                    [
                                      _c("b-form-input", {
                                        ref: "phone",
                                        attrs: {
                                          required: true,
                                          placeholder: "Telefono",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.fields.phone,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.fields, "phone", $$v)
                                          },
                                          expression: "fields.phone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12", sm: "6" },
                                    },
                                    [
                                      _c("b-form-input", {
                                        ref: "address",
                                        attrs: {
                                          required: true,
                                          placeholder: "Indirizzo",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.fields.address,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.fields, "address", $$v)
                                          },
                                          expression: "fields.address",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12", sm: "6" },
                                    },
                                    [
                                      _c("b-form-input", {
                                        ref: "city",
                                        attrs: {
                                          required: true,
                                          placeholder: "Città",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.fields.city,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.fields, "city", $$v)
                                          },
                                          expression: "fields.city",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12", sm: "6" },
                                    },
                                    [
                                      _c("b-form-input", {
                                        ref: "province",
                                        attrs: {
                                          required: true,
                                          placeholder: "Provincia",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.fields.province,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.fields,
                                              "province",
                                              $$v
                                            )
                                          },
                                          expression: "fields.province",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12", sm: "6" },
                                    },
                                    [
                                      _c("b-form-input", {
                                        ref: "postalCode",
                                        attrs: {
                                          required: true,
                                          placeholder: "CAP",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.fields.postalCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.fields,
                                              "postalCode",
                                              $$v
                                            )
                                          },
                                          expression: "fields.postalCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12", sm: "6" },
                                    },
                                    [
                                      _c("b-form-input", {
                                        ref: "email",
                                        attrs: {
                                          required: true,
                                          placeholder: "Email",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.fields.email,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.fields, "email", $$v)
                                          },
                                          expression: "fields.email",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12", sm: "6" },
                                    },
                                    [
                                      _c("b-form-input", {
                                        ref: "emailConfirm",
                                        attrs: {
                                          required: true,
                                          placeholder: "Conferma email",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.fields.emailConfirm,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.fields,
                                              "emailConfirm",
                                              $$v
                                            )
                                          },
                                          expression: "fields.emailConfirm",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mt-3 d-flex",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c("b-form-checkbox", {
                                        ref: "terms",
                                        attrs: {
                                          required: "",
                                          type: "checkbox",
                                          name: "checkbox",
                                          id: "checkboxTerms",
                                        },
                                        model: {
                                          value: _vm.checkboxTerms,
                                          callback: function ($$v) {
                                            _vm.checkboxTerms = $$v
                                          },
                                          expression: "checkboxTerms",
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "checkTermsPrivacy",
                                          attrs: { for: "checkboxTerms" },
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "nounderline",
                                              attrs: {
                                                to: { name: "terms" },
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "secondary" },
                                                [
                                                  _vm._v(
                                                    "Accetto le condizioni d'uso"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "d-flex",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c("b-form-checkbox", {
                                        ref: "privacy",
                                        attrs: {
                                          required: "",
                                          type: "checkbox",
                                          name: "checkbox",
                                          id: "checkboxPrivacy",
                                        },
                                        model: {
                                          value: _vm.checkboxPrivacy,
                                          callback: function ($$v) {
                                            _vm.checkboxPrivacy = $$v
                                          },
                                          expression: "checkboxPrivacy",
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "checkTermsPrivacy",
                                          attrs: { for: "checkboxPrivacy" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "secondary" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: _vm.site.urlPrivacy,
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Accetto le condizioni di Privacy"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.validateFields()
                                ? _c(
                                    "div",
                                    { staticClass: "mt-4" },
                                    [
                                      _c("Stripe", {
                                        ref: "stripe",
                                        attrs: {
                                          amount: Number(
                                            _vm.payment_get.live_price
                                          ),
                                        },
                                        on: {
                                          "stripe-change": _vm.stripeChange,
                                          "stripe-confirm": _vm.stripeConfirm,
                                        },
                                      }),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn btn-block btn-success mt-3",
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.stripe.getIntentClientSecret()
                                            },
                                          },
                                        },
                                        [_vm._v("Continua")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "b-row",
                    [
                      _vm.purchasing
                        ? _c(
                            "b-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [_c("Spinner")],
                            1
                          )
                        : _c(
                            "b-col",
                            { attrs: { md: "6", "offset-md": "3" } },
                            [
                              _vm.checkout_message
                                ? _c(
                                    "h2",
                                    { staticClass: "vb-heading mb-4 pb-4" },
                                    [_vm._v(_vm._s(_vm.checkout_message))]
                                  )
                                : _vm._e(),
                              _c("p", [
                                _c("strong", [_vm._v("Tipo di servizio:")]),
                                _vm._v(
                                  " " + _vm._s(_vm.payment_get.productName)
                                ),
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v("Descrizione del servizio:"),
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.payment_get.description)
                                ),
                              ]),
                              _c(
                                "p",
                                { staticClass: "paymentslink__price mt-4" },
                                [
                                  _vm._v(
                                    "Importo: € " +
                                      _vm._s(_vm.payment_get.live_price)
                                  ),
                                ]
                              ),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.copy_security),
                                },
                              }),
                            ]
                          ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm.payment_get.status === "COMPLETE"
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "6", "offset-md": "3" } }, [
                !_vm.completed && !_vm.purchasing
                  ? _c("h2", { staticClass: "vb-heading mb-4 pb-4" }, [
                      _vm._v(
                        "\n        Questa pagina di pagamento non è più attiva perchè il pagamento è stato già completato\n      "
                      ),
                    ])
                  : _vm._e(),
                _c("p", [
                  _c("strong", { staticClass: "d-block mb-2" }, [
                    _vm._v("Perchè non posso effettuare il pagamento?"),
                  ]),
                  _vm._v(
                    "\n        Molto probabilmente hai già effettuato il pagamento.\n      "
                  ),
                ]),
                _c("p", { staticClass: "mt-4" }, [
                  _c("strong", { staticClass: "d-block mb-2" }, [
                    _vm._v("Che cosa posso fare?"),
                  ]),
                  _vm._v(
                    "\n        Controlla la tua posta elettronica e verifica se hai ricevuto la mail di conferma del pagamento.\n        Diversamente contatta la nostra assistenza ai riferimenti che trovi in questa stessa pagina.\n      "
                  ),
                ]),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.copy_security) },
                }),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.payment_get.status === "EXPIRED"
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "6", "offset-md": "3" } }, [
                !_vm.completed && !_vm.purchasing
                  ? _c("h2", { staticClass: "vb-heading mb-4 pb-4" }, [
                      _vm._v("Questa pagina di pagamento non è più attiva"),
                    ])
                  : _vm._e(),
                _c("p", [
                  _c("strong", { staticClass: "d-block mb-2" }, [
                    _vm._v("Perchè non posso effettuare il pagamento?"),
                  ]),
                  _vm._v(
                    "\n        Il pagamento del servizio aveva una scadenza temporale legata a disponibilità o altre condizioni:\n        il termine è stato probabilmente superato.\n      "
                  ),
                ]),
                _c("p", { staticClass: "mt-4" }, [
                  _c("strong", { staticClass: "d-block mb-2" }, [
                    _vm._v("Che cosa posso fare?"),
                  ]),
                  _vm._v(
                    "\n        Contatta la nostra assistenza ai contatti che trovi in questa stessa pagina.\n      "
                  ),
                ]),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.copy_security) },
                }),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }