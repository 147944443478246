<template>
  <div id="info" class="publicPage chi-siamo">
    <div class="heroContainer">
      <img src="@/assets/images/chi_siamo_header.jpg" id="about">
      <div class="publicBookingEngine">
        <SearchBar
          :loading="false"
          :band="activeBand"
          :focus="''"
          :geo="geo"
          :dates="dates"
          :occupancy="occupancy"
          :hasResults="false"
          :offset="0"
          :lastSearch="''"
          v-on:submit-search="mixinSendMutation('setTriggerSearch',true);mixinGoTo('search',{})"
          v-on:filter-changed="{}"
          :showUserBox="false"
        />
      </div>
    </div>
    <div id="page">
      <b-container>
        <section>
          <b-row>
            <b-col sm="12">
              <br/>
              <p>
                Salabam, sviluppato da Salabam Solutions, è il prodotto che ha rivoluzionato il mondo dei cofanetti viaggio,
                introducendo il primo voucher per viaggiare completamente digitale con ben 16 mesi di validità e oltre
                600.000 strutture convenzionate tra hotel, appartamenti, case e B&B.<br><br>
                A differenza dei cofanetti viaggio classici, Salabam permette di consultare in tempo reale una selezione
                premium del catalogo Expedia, il leader mondiale dell’online travel, con cui abbiamo una partnership
                diretta e di lunga data.
              </p>
            </b-col>
          </b-row>
        </section>
        <br/><br/>
        <section id="per-i-viaggiatori">
          <b-row>
            <b-col sm="12">
              <h3>Come utilizzare il tuo Salabam</h3>
              <br/>
              <p>
                I voucher si differenziano per destinazione, numero di notti e fascia di prezzo iniziale.<br>
                Sono disponibili Salabam Italia, Europa e Mondo, a partire da 75 euro per notte.
                I Salabam sono personalizzabili: sia la destinazione che il numero di notti possono essere modificati,
                per lasciare ai viaggiatori la massima libertà di crearsi un viaggio su misura.
              </p>

            </b-col>
          </b-row>
          <br/>
          <b-row>
            <b-col sm="12" lg="5">
              <img src="@/assets/images/chi_siamo_1.png" alt="Chi siamo: Salabam per i viaggiatori">
            </b-col>
            <b-col sm="12" lg="7">
              <p>
                La prenotazione avviene in due fasi.<br>
                Prima si sceglie la tipologia di voucher, per poi tornare sul sito
                con le credenziali del cofanetto acquistato, verificare le disponibilità e concludere la prenotazione.
                Questo significa che ci sono ben sedici mesi di tempo per scegliere in tutta calma la destinazione.
              </p>
            </b-col>
          </b-row>
          <br/><br/>
          <b-row>
            <b-col sm="12">
              <p>
                Non è necessario contattare le strutture: basta accedere al sito con le credenziali del Salabam acquistato,
                eseguire una ricerca e scegliere tra le tantissime strutture disponibili.
                A prenotazione conclusa, si riceve una mail con tutti i dettagli e i codici di prenotazione da mostrare
                in struttura al momento del check-in. Saremo noi a eseguire un doppio check per accertarci che la
                prenotazione sia andata a buon fine.
              </p>
            </b-col>
          </b-row>
          <TornaIndietro />
        </section>
        <br/><br/>
        <section id="per-le-aziende">
          <b-row>
            <b-col sm="12">
              <h3>Sei un'azienda e vuoi costruire un progetto con Salabam Solutions?</h3>
            </b-col>
          </b-row>
          <br/>
          <b-row>
            <b-col sm="12" lg="7">
              <p>
                A Salabam facciamo tutto in casa: dalle piattaforme, sviluppate da un team informatico interno,
                all’assistenza clienti multicanale e multilingue, rapida e senza robot.<br>
                Il risultato? Soggiorni in totale sicurezza presso strutture di verificata professionalità e qualità.
                E per qualsiasi informazione o necessità, un personale dedicato risponde dall’Italia nel giro di pochi minuti.
              </p>
            </b-col>
            <b-col sm="12" lg="5">
              <img src="@/assets/images/chi_siamo_2.png" alt="Chi siamo: Salabam per le aziende">
            </b-col>
          </b-row>
          <br/>
          <b-row>
            <b-col sm="12">
              <p>
                Se ti interessa scoprire l’intera gamma di servizi che possiamo offrire a te o alla tua azienda,<br>
                visita <a href="https://salabamsolutions.com">il sito Salabam Solutions!</a>
              </p>
            </b-col>
          </b-row>
          <br/>
          <TornaIndietro />
        </section>
        <br/><br/>
      </b-container>
    </div>
  </div>
</template>

<script>
import TornaIndietro from '@/components/blocks/common/torna-indietro'
import SearchBar from '@/components/blocks/search-bar'

export default {
  name: 'chi-siamo',
  metaInfo: {
    title: 'La nostra azienda',
    meta: [
      { vmid: 'metaDescription', name: 'Description', content: 'Ecco la nostra realtà fatta di competenza, tecnologia e attenzione al cliente. Scopri perchè Salabam è la scelta di centinaia di aziende italiane' },
      { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Ecco la nostra realtà fatta di competenza, tecnologia e attenzione al cliente. Scopri perchè Salabam è la scelta di centinaia di aziende italiane' },
    ],
  },
  components: {
    TornaIndietro,
    SearchBar,
  },
  computed:
  {
    occupancy: {
      get() { return this.$store.state.occupancy },
      set(value) { this.mixinSendMutation('setOccupancy',value) }
    },
    /*
    triggerSearch: {
      get() { return this.$store.state.triggerSearch },
      set(value) { this.mixinSendMutation('setTriggerSearch',value) }
    },*/
    dates: {
      get() { return this.$store.state.dates },
      set(value) { this.mixinSendMutation('setDates',value) }
    },
    geo: {
      get() { return this.$store.state.geo },
      set(value) { this.mixinSendMutation('setGeo',value) }
    },
    activeBand: {
      get() { return this.$store.state.activeBand },
      set(value) {
        this.mixinSendMutation('setActiveBand',value)
      }
    },
  },  
  data() {
    return {
    }
  },
  mounted() {

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './../../../scss/_custom.scss';
#info
{
  img
  {
    width:100%;
  }
}
</style>