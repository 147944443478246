import { render, staticRenderFns } from "./warning-exclamation.vue?vue&type=template&id=9710599e&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/salabam.com/cancellara-src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9710599e')) {
      api.createRecord('9710599e', component.options)
    } else {
      api.reload('9710599e', component.options)
    }
    module.hot.accept("./warning-exclamation.vue?vue&type=template&id=9710599e&", function () {
      api.rerender('9710599e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/svg/warning-exclamation.vue"
export default component.exports